<template>
    <div v-if="show">
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.zones')}}
                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button @click="setOperation('add')" variant="info" size="sm"
                                                      :title="$t('button.title.addNewItem')"
                                                      v-if="$global.hasPermission('zonesstore')" v-b-tooltip.hover>
                                                <i class="fe fe-plus"></i> {{$t('button.addNew')}}
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('zonesview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="zones-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(zip_ranges)="record">
                            <span class="float-left mt-0" v-if="record.item.zip_ranges" size="sm" v-for="(zr, index) in record.item.zip_ranges">
                                {{record.item.zip_ranges.length > 1 && index > 0 ? ', ' : ''}} {{zr.zip_from}} - {{zr.zip_to }}
                            </span>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')" v-if="$global.hasPermission('zonesupdate')"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('zonesdestroy')">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class=" ml-1"
                                   :title="$t('button.title.deleteItem')"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.zones-table -->
                <div class="zones-operation">
                    <a-drawer
                        placement="right"
                        :width="'720px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12">
                                    <b-col cols="12" v-show="global.pendingRequests > 0">
                                        <a-skeleton active :paragraph="{ rows: 8 }"/>
                                    </b-col>
                                    <b-row v-show="global.pendingRequests <= 0">
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.name')+' *'"
                                                label-for="name"
                                                :invalid-feedback="formErrors.first('name')"
                                            >
                                                <b-form-input
                                                    id="name"
                                                    v-model="formFields.name"
                                                    type="text"
                                                    :state="((formErrors.has('name') ? false : null))"
                                                    ref="title"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="12">
                                            <b-form-group
                                                :label="$t('input.country')+' *'"
                                                label-for="country"
                                                :invalid-feedback="formErrors.first('country')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.countries"
                                                    placeholder=""
                                                    v-model="formFields.country"
                                                    :class="[{'invalid is-invalid': (formErrors.has('country'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                </b-col><!--/b-col-->
                                <!--# Start zone zip ranges #-->
                                <b-col lg="12" md="12" sm="12" class="mt-3">
                                    <b-card class="mb-0">
                                        <b-card-header v-b-toggle.zip-ranges class="p-0">
                                            <h4 class="mb-0">
                                                <span class="badge badge-primary">
                                                    {{$t('title.zipRanges')}}
                                                </span>
                                                <small v-show="zip_ranges.length > 0">
                                                    - {{zip_ranges.length}} Item/s
                                                </small>
                                                <div class="invalid-feedback d-block"
                                                     v-if="formErrors.has('zip_ranges')">
                                                    <small>{{formErrors.first('zip_ranges')}}</small>
                                                </div>
                                            </h4>
                                        </b-card-header><!-- /.p-0-->

                                        <b-collapse visible id="zip-ranges">
                                            <div class="bg-light p-3">
                                                <b-row>
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :label="$t('input.zipFrom')+' *'"
                                                            label-for="zip_from"
                                                            :invalid-feedback="formErrors.first('zip_from')"
                                                        >
                                                            <b-form-input
                                                                id="zip_from"
                                                                v-model="zip_range.zip_from"
                                                                type="number"
                                                                :state="((formErrors.has('zip_from') ? false : null))"
                                                                ref="zip_from"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col sm="4">
                                                        <b-form-group
                                                            :label="$t('input.zipTo')+' *'"
                                                            label-for="zip_to"
                                                            :invalid-feedback="formErrors.first('zip_to')"
                                                        >
                                                            <b-form-input
                                                                id="zip_to"
                                                                v-model="zip_range.zip_to"
                                                                type="number"
                                                                :state="((formErrors.has('zip_to') ? false : null))"
                                                                ref="zip_to"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="info" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="handleAddUpdateZoneZipRangeClick()">
                                                                <i class="fa fa-plus"></i>
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="warning" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="resetZoneZipRange()">
                                                                {{$t('button.reset')}}
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                                <b-row>
                                                    <b-col cols="12">
                                                        <table class="table table-bordered bg-white">
                                                            <thead>
                                                            <tr>
                                                                <th width="50">#</th>
                                                                <th>{{$t('column.zipFrom')}}</th>
                                                                <th>{{$t('column.zipTo')}}</th>
                                                                <th width="80"></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(zr, index) in zip_ranges"
                                                                :class="[{'table-primary': zr.token === zip_range.token}]">
                                                                <td>{{index + 1}}</td>
                                                                <td>
                                                                    {{zr.zip_from}}
                                                                </td>
                                                                <td>
                                                                    {{zr.zip_to}}
                                                                </td>
                                                                <td>
                                                                    <a @click="handleEditZoneZipRangeClick(zr.token)"
                                                                       :title="$t('button.title.editItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-edit"></i>
                                                                    </a>
                                                                    <a-popconfirm title="Are you sure？"
                                                                                  @confirm="handleDeleteZoneZipRangeClick(zr.token)">
                                                                        <i slot="icon" class="fe fe-trash"></i>
                                                                        <a class=" ml-1"
                                                                           :title="$t('button.title.deleteItem')"
                                                                           v-b-tooltip.hover>
                                                                            <i class="fe fe-trash"></i>
                                                                        </a>
                                                                    </a-popconfirm>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            <tfoot v-show="zip_ranges.length <= 0">
                                                            <tr>
                                                                <th colspan="4">
                                                                    {{$t('title.noDataAvailable')}}
                                                                </th>
                                                            </tr>
                                                            </tfoot>
                                                        </table><!-- /.table table-bordered -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                            </div><!-- /.bg-light -->
                                        </b-collapse><!-- /#zone-zip-ranges -->
                                    </b-card><!-- /b-card -->
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </b-row><!--/b-row-->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.zones-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.country')+' *'">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.countries"
                                            placeholder=""
                                            v-model="filters.countries"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromAddedDate')"
                                        label-for="fromDate">
                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toAddedDate')"
                                        label-for="toDate">
                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import ZoneZipRangeMixing from "./ZoneZipRangeMixing"

    const FORM_STATE = {
        name: null,
        country: null,
        zip_ranges: [],
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        countries: [],
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.name'),
            key: 'name',
            sortable: true,
            sortKey: 'name',
        },
        {
            label: self.$t('column.country'),
            key: 'country',
            sortable: true,
            sortKey: 'country',
        },
        {
            label: self.$t('column.zipRanges'),
            key: 'zip_ranges',
            sortable: false,
        },
        (self.$global.hasAnyPermission(['zonesupdate', 'zonesdestroy'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, ZoneZipRangeMixing],
        components: {
            Datepicker,
            Treeselect
        },
        data() {
            return {
                operationTitle: 'title.zones',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'zones',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    countries: [],
                },
                show: true,
            }
        },
        mounted() {
            this.getCountries();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.zones')
        },
        methods: {
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = `title.${operation}Zone`;
                this.zip_ranges.length = 0
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'zones/update' : 'zones/create',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            zip_ranges: this.zip_ranges
                        },
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/zones/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/zones/detail/${id}`,
                    })
                    const {data} = response
                    const {zip_ranges} = data
                    this.formFields = {...data, is_active: (data.is_active > 0)}

                    this.zip_ranges = zip_ranges.map((item) => {
                        return {
                            id: item.id,
                            token: item.token,
                            zip_from: item.zip_from,
                            zip_to: item.zip_to
                        }
                    })
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('zonesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
