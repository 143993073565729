import Error from '../../util/Error'

const ZONE_ZIP_RANGE_STATE = {
    token: null,
    id: null,
    zip_from: null,
    zip_to:null,
    error: false
};

export default {
    data() {
        return {
            zip_range: {...ZONE_ZIP_RANGE_STATE},
            zip_ranges: [],
        }
    },
    methods: {
        handleAddUpdateZoneZipRangeClick() {
            this.zip_range.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.zip_range.zip_from) {
                this.zip_range.error = true;
                errors.zip_from = [this.$t('validation.required')];
            }

            if (!this.zip_range.zip_to) {
                this.zip_range.error = true;
                errors.zip_to = [this.$t('validation.required')];
            }

            _.map(this.zip_ranges, (range) => {
                if(range.token !== this.zip_range.token) {
                    if(range.zip_from === this.zip_range.zip_from && range.zip_to === this.zip_range.zip_to) {
                        errors.zip_from = [this.$t('validation.duplicate')];
                        this.zip_range.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if(this.zip_range.error) return false;

            const entity = this.zip_range;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.zip_ranges, {token: entity.token});

            if (this.zip_ranges[index] !== undefined) {
                this.zip_ranges[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                this.zip_ranges.push(entity)
            }

            this.zip_range = {...ZONE_ZIP_RANGE_STATE, token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
        },
        handleDeleteZoneZipRangeClick(token) {
            const index = _.findIndex(this.zip_ranges, {token: token})
            if (this.zip_ranges[index] !== undefined) {
                this.zip_ranges.splice(index, 1);
            }
        },
        handleEditZoneZipRangeClick(token) {
            const index = _.findIndex(this.zip_ranges, {token: token})
            if (this.zip_ranges[index] !== undefined) {
                this.zip_range = {...this.zip_ranges[index]};
            }
        },
        resetZoneZipRange() {
            this.zip_range = {...ZONE_ZIP_RANGE_STATE}
            this.zip_range.error = false;
        },
    }
}
